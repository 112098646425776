<template>
  <div class="patients-list-view h-auto">
    <app-header icon="user" title="Clientes"></app-header>

    <section>
      <div class="row mt-2 justify-content-around align-items-middle">
        <div class="col-12 col-lg-12 col-md-12 col-sm-6">
          <div class="card">
            <div class="card-content">
              <div class="card-title">FILTROS</div>

              <div class="row d-flex">
                <div class="col-3 col-lg-3 col-md-3 col-sm-3 card-select">
                  <div class="card-select-title">Cliente</div>

                  <app-input
                    v-model="searchTerm"
                    placeholder="Nome, e-mail ou CPF/CNPJ"
                    icon="search"
                    type="is-primary"
                  >
                  </app-input>
                </div>

                <div
                  class="col-2 col-lg-2 col-md-2 col-sm-2 d-flex align-items: middle"
                >
                  <b-tooltip label="FILTROS">
                    <b-checkbox
                      v-model="checked"
                      class="mr-0"
                      label="Filtrar resultados"
                      style="margin-top: 25px"
                    />
                  </b-tooltip>
                  <span style="padding-top: 23px">OUTROS FILTROS</span>
                </div>

                <div class="col-5 col-lg-5 col-md-5 col-sm-4">
                  <b-tooltip label="FILTRAR">
                    <b-button
                      type="is-primary"
                      icon-right="search"
                      style="margin-top: 10px; margin-right: 15px"
                      @click="load()"
                      >FILTRAR</b-button
                    >
                  </b-tooltip>

                  <b-tooltip label="LIMPAR FILTROS">
                    <b-button
                      type="is-primary"
                      icon-right="recycle"
                      style="margin-top: 10px; margin-right: 15px; width: 20px"
                      @click="limpar_filtros()"
                    ></b-button>
                  </b-tooltip>

                  <b-tooltip label="NOVO CLIENTE">
                    <router-link
                      :to="{
                        name: 'patients.save',
                      }"
                    >
                      <b-button
                        type="is-primary"
                        icon-right="plus"
                        style="margin-top: 10px; margin-right: 15px"
                        >NOVO</b-button
                      >
                    </router-link>
                  </b-tooltip>

                  <b-tooltip label="EXPORTAR XLS">
                    <b-button
                      @click="exportPatients()"
                      type="is-primary"
                      icon-right="file-excel"
                      :disabled="isExporting"
                      :loading="isExporting"
                      style="margin-top: 10px; margin-right: 15px"
                    >
                      EXPORTAR XLS
                    </b-button>
                  </b-tooltip>

                  <b-tooltip
                    v-if="patient_selected_email"
                    label="REDEFINIR SENHA"
                  >
                    <b-button
                      @click="onRequestPasswordReset()"
                      type="is-primary"
                      icon-right="key"
                      style="margin-top: 10px; margin-right: 15px"
                    >
                      REDEFINIR SENHA
                    </b-button>
                  </b-tooltip>
                </div>
              </div>

              <div v-if="checked" class="row d-flex">
                <div class="col-2 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Selecione um período</div>
                  <b-field>
                    <b-select
                      @input="onPeriodFilterChange()"
                      expanded
                      name="period"
                      v-model="periodFilter"
                      placeholder="Selecionar"
                    >
                      <b-select-option
                        v-for="period of periods"
                        :key="period.id"
                        :value="period.id"
                      >
                        {{ period.name }}
                      </b-select-option>
                    </b-select>
                  </b-field>
                </div>

                <div
                  class="col-2 col-lg-2 col-md-2 col-sm-1 card-select"
                  v-if="isCustomPeriod"
                >
                  <div class="card-select-title">Selecione as datas</div>
                  <b-field>
                    <b-datepicker
                      placeholder="Escolha as datas"
                      @input="onRangeChange"
                      clearable
                      range
                    >
                    </b-datepicker>
                  </b-field>
                </div>

                <div class="col-11 col-lg-2 col-md-2 col-sm-1 card-select">
                  <div class="card-select-title">Ativo/Inativo</div>

                  <b-field>
                    <b-select
                      v-model="searchActive"
                      placeholder="Selecione..."
                      expanded
                    >
                      <b-select-option :value="-1">Todos</b-select-option>
                      <b-select-option :value="1">Ativo</b-select-option>
                      <b-select-option :value="0">Inativo</b-select-option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-lg-12 col-md-12 col-sm-6">
          <b-table
            id="table-patients"
            class="table-custom"
            :data="patients_list"
            :per-page="perPage"
            :current-page.sync="page"
            :total="total"
            :loading="isLoading"
            :paginated="false"
            :pagination-simple="false"
            pagination-position="both"
            default-sort-direction="asc"
            :pagination-rounded="false"
            sort-icon="arrow-down"
            sort-icon-size="is-small"
            default-sort="row.name"
            @select="onRowClicked"
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column
              v-slot="props"
              field="id"
              sortable
              label="ID"
              centered
            >
              {{ props.row.id }}
            </b-table-column>

            <b-table-column v-slot="props" field="name" sortable label="NOME">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column v-slot="props" field="email" label="EMAIL" sortable>
              {{ props.row.user.email || "-" }}
            </b-table-column>

            <b-table-column v-slot="props" field="cpf" label="CPF" centered>
              {{
                props.row.cpf
                  | mask(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
              }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="phone"
              label="TELEFONE"
              centered
            >
              <div v-if="props.row.phone > 0">
                {{ toPhone(props.row.phone) }}
              </div>
              <div v-else class="text-center">-</div>
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="created_at"
              label="CADASTRO"
              sortable
              centered
            >
              {{ toDate(props.row.created_at) }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="schedules_count"
              label="ATENDIMENTOS"
              sortable
              centered
            >
              {{ props.row.schedules_count }}
            </b-table-column>

            <b-table-column
              v-slot="props"
              field="schedules_sum"
              label="VALORES"
              sortable
              centered
            >
              <div v-if="props.row.schedules_sum > 0" class="ctab-r">
                {{ (props.row.schedules_sum || "-") | currency("BRL") }}
              </div>
              <div v-else class="text-center">-</div>
            </b-table-column>

            <b-table-column v-slot="props" label="ATIVO" centered>
              <b-checkbox
                true-value="1"
                false-value="0"
                v-model="props.row.active"
                :patient_id="props.row.id"
                :value="props.row.active"
                class="mr-4"
                @input="
                  activePatient(props.row.active, props.row.id, props.row)
                "
              />
            </b-table-column>

            <b-table-column width="20%" v-slot="props" label="OPÇÕES" centered>
              <router-link
                :to="{
                  name: 'patients.save',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Editar" class="m-1">
                  <b-button
                    type="is-primary"
                    size="is-small"
                    icon-right="edit"
                  />
                </b-tooltip>
              </router-link>
              <b-tooltip label="Deletar" class="m-1">
                <b-button
                  @click="onClickDelete(props.row.id)"
                  type="is-primary"
                  size="is-small"
                  icon-right="trash"
                />
              </b-tooltip>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum registro encontrado.</p>
                </div>
              </section>
            </template>

            <template #footer v-if="total > 0">
              <div class="row align-middle text-center">
                <div class="col-5 col-lg-5 col-md-5 col-sm-3 ptext text-right">
                  Exibindo {{ rows }} de {{ total }}
                </div>

                <div class="col-auto text-center">
                  <b-pagination
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                    aria-controls="table-patients"
                  >
                  </b-pagination>
                </div>

                <div class="col-auto text-center">
                  <b-select
                    class="pselect"
                    v-model="perPage"
                    style="margin-left: 10px"
                  >
                    <b-select-option
                      v-for="i in perPageOptions"
                      :key="i"
                      :value="i"
                    >
                      <b>{{ i }}</b>
                    </b-select-option>
                  </b-select>
                </div>

                <div class="col-auto">
                  <div class="ctab-r col-auto col-lg-12 col-md-12 col-sm-6">
                    <p>Total de clientes cadastrados: {{ total_patients }}</p>

                    <p>
                      Valor total dos atendimentos:
                      {{ (total_amount || 0) | currency("BRL") }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import * as moment from "moment";

import PatientsService from "@/services/patients.service";
import adminsService from "@/services/admins.service";

import PERIODS from "@/constants/dashboard/periods-filter.constant";

import BuefyMixin from "@/mixins/BuefyMixin";

export default {
  mixins: [BuefyMixin],

  mounted() {
    this.load();
  },

  beforeMount() {
    this.onPeriodFilterChange();
  },

  data: () => ({
    isLoading: true,
    isExporting: false,
    periods: PERIODS,
    checked: false,
    links: [],
    datesFilter: [],
    data: [],
    page: 1,
    perPageOptions: [10, 20, 40, 80],
    perPage: 10,
    total: 0,
    total_patients: 0,
    total_amount: 0,
    searchTerm: "",
    periodFilter: 10,
    selectedPeriod: 10,
    sorter: "asc,name",
    onlines: "",
    status: -1,
    searchActive: -1,
    dates: [],
    items: "",
    patient_selected_email: null,
  }),

  computed: {
    isCustomPeriod() {
      const period = this.periods.find(
        (period) => period.id == this.periodFilter
      );

      return period?.custom ?? false;
    },

    patients_list() {
      return this.data;
    },

    rows() {
      return this.data.length;
    },
  },

  watch: {
    searchTerm: debounce(function () {
      this.page = 1;
      this.load();
    }, 1000),

    onlines() {
      this.page = 1;
    },

    searchActive() {
      this.page = 1;
    },

    sorter() {
      this.page = 1;
    },

    page() {
      if (this.data.length > 0 && this.data.length < this.total) this.load();
    },

    perPage() {
      this.load();
    },
  },

  methods: {
    load() {
      const params = this.filters();

      this.isLoading = true;

      PatientsService.get(params)
        .then(({ data }) => {
          const { total, data: items, current_page, per_page, links } = data;

          this.total = total;
          this.page = current_page;
          this.perPage = per_page;
          this.data = Object.values(items);
          this.links = links;

          const values = this.data?.map((x) => x.schedules_sum);
          this.total_amount = values.reduce((x, y) => (x += y), 0);

          this.total_patients = data.total;
        })
        .finally(() => (this.isLoading = false));
    },

    onRangeChange(values) {
      const dates = values.map((date) => moment(date).format("YYYY-MM-DD"));
      this.dates[1] = moment(this.dates[1]).add(1, "day").format("YYYY-MM-DD");
      this.selectedPeriod = `${dates[0]},${dates[1]}`;
    },

    onPeriodFilterChange() {
      const period = this.periods.find((item) => item.id == this.periodFilter);
      if (!period.custom) {
        this.page = 1;
        const { start, end } = period;

        this.dates = [
          moment(start).format("YYYY-MM-DD"),
          moment(end).format("YYYY-MM-DD"),
        ];
      } else {
        this.dates = [];
      }
    },

    onRowClicked(patient) {
      this.patient_selected_email = patient.user.email;
    },

    onRequestPasswordReset() {
      if (!this.patient_selected_email) return;

      adminsService
        .resetPassword(this.patient_selected_email)
        .then(async ({ data }) => {
          let message = "Não foi possível copiar o link";
          let type = "is-warning";

          if (data?.link) {
            await window.navigator.clipboard.writeText(data.link);
            message = "Link copiado para área de transferência";
            type = "is-success";
          }

          this.$buefy.snackbar.open({
            message,
            type,
            duration: 5000,
            pauseOnHover: true,
          });
        })
        .catch(() =>
          this.$buefy.snackbar.open({
            message: "Erro ao gerar link para redefinição de senha",
            type: "is-danger",
            duration: 5000,
            pauseOnHover: true,
          })
        );
    },

    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        title: "Excluir Cliente",
        message:
          "Tem certeza que deseja <b>excluir</b> este cliente? Essa ação não pode ser desfeita.",
        cancelText: "Cancelar",
        confirmText: "Excluir Cliente",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.delete(id),
      });
    },

    toDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD/MM/YYYY");
      }
    },

    toPhone(value) {
      if (value.length == 10) {
        return value.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
      }
      if (value.length == 11) {
        return value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
      }
    },

    limpar_filtros() {
      this.searchTerm = "";
      this.searchActive = -1;
      this.searchDate = null;
      this.searchDateEnd = null;
      this.sorter = false;
      this.datesFilter = [];
      this.periodFilter = 10;
      this.checked = false;
      this.onPeriodFilterChange();
      this.load();
    },

    filters() {
      const params = {
        page: this.page,
        perPage: this.perPage,
      };

      if (this.searchActive > -1) {
        params["filter[active]"] =
          this.searchActive > -1 ? this.searchActive : "";
      }

      if (this.dates.length == 2) {
        params[
          "filter[start_end_between]"
        ] = `${this.dates[0]},${this.dates[1]}`;
      }

      if (this.searchTerm) {
        params[
          "filter[name_email_or_document]"
        ] = this.searchTerm.toLowerCase().trim();
      }

      return params;
    },

    activePatient(value, patient_id, row) {
      PatientsService.patch(patient_id, { active: value })
        .then(() => {
          this.$buefy.snackbar.open("Cliente atualizado com sucesso!");
        })
        .catch(({ response }) => {
          const { message, errors } = response;

          if (response.status === 422) {
            this.errors = errors;
            value == 1 ? (row.active = 0) : (row.active = 1);
          } else
            this.$buefy.snackbar.open(
              message || "Erro ao tentar a atualizar o Cliente.",
              "error"
            );
          value == 1 ? (row.active = 0) : (row.active = 1);
        })
        .finally(() => (this.isSaving = false));
    },

    delete(id) {
      PatientsService.delete(id)
        .then(() => {
          this.$buefy.snackbar.open("Cliente excluído com sucesso.");
          this.load();
        })
        .catch(({ response }) => {
          if (response.status != 200) {
            this.$buefy.snackbar.open(
              response.message || "Erro ao tentar excluir o Cliente.",
              "error",
              4000
            );
          }
        });
    },

    exportPatients() {
      this.isExporting = true;

      const params = {};

      if (this.searchTerm) {
        params["name_email_or_document"] = this.searchTerm.toLowerCase().trim();
      }

      if (this.searchActive > -1) {
        params["active"] = this.searchActive > -1 ? this.searchActive : "";
      }

      if (this.dates.length == 2) {
        params["start_end_between"] = `${this.dates[0]},${this.dates[1]}`;
      }

      PatientsService.export({ filters: params })
        .then(({ data }) => {
          const link = document.createElement("a");
          link.target = "_blank";
          link.href = data;
          link.click();
        })
        .catch(({ response }) => {
          const message =
            response.data.message || "Erro ao realizar exportação.";
          this.$buefy.snackbar.open(message);
        })
        .finally(() => (this.isExporting = false));
    },

    toStringMthod(value) {
      if (!value) {
        return "";
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map((key) => toString(value[key]))
          .join(" ");
      }
      return String(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  tbody tr:hover {
    background: gray;
  }
}

.filters {
  align-items: center;
}
.separated-table {
  margin: 5px 0;
}
</style>

<style lang="scss">
.table-custom {
  height: auto !important;

  & table tbody tr {
    &:hover {
      cursor: pointer;
      background-color: #e6e6e6;
    }
  }
}
</style>
